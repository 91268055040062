import React, { useRef } from 'react';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Select } from 'antd';

import { IDepartmentHeadForm, IDepartmentHeadPayLoad } from '../core/models';
import { SCreateUser, SUpdateUser } from '../../../apis/app.service';

import AppButton from '../../sharedcomponents/Buttons/AppButton/AppButton';
import { useUsersPage } from '../../../store/UsersProvider';
import { useAuth } from '../../../store/AuthProvider';

const DepartmentHeadSchema = Yup.object({
  name: Yup.string().required().label('Name'),
  email: Yup.string().email().required().label('Email'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{9}$/, 'Phone number must be exactly 9 digits')
    .required()
    .label('Phone'),
  department: Yup.string().required().label('Department'),
});

export default function AddDepartmentHeadForm() {
  const queryClient = useQueryClient();
  const { departmentHead, setDepartmentHead, handleToggle, institutionHead } =
    useUsersPage();
  const { action, data } = departmentHead;
  const {
    data: { department },
  } = institutionHead;
  const { user } = useAuth();

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const MCreateDepartmentHead = useMutation({
    mutationFn: SCreateUser,
    onSuccess: (res, variable) => {
      queryClient.invalidateQueries({
        queryKey: ['departmentHeads'],
      });

      const { message } = res;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });

      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const MUpdateDepartmentHead = useMutation({
    mutationFn: SUpdateUser,
    onSuccess: (res, variable) => {
      queryClient.invalidateQueries({
        queryKey: ['departmentHeads'],
      });
      const payload = variable.body as IDepartmentHeadPayLoad;

      const data: IDepartmentHeadForm = {
        id: variable.userId,
        name: payload.name,
        email: payload.email,
        phoneNumber: payload.phoneNumber.slice(4),
        department: payload.department.join(','),
      };
      setDepartmentHead({ action: 'edit', data });
      const { message } = res;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });

      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const submitDepartmentHeadForm = async (
    values: IDepartmentHeadForm,
    actions: FormikValues
  ) => {
    const payload: IDepartmentHeadPayLoad = {
      ...values,
      department: [values.department],
      phoneNumber: `+256${values.phoneNumber}`,
      type: 'external',
      parent: institutionHead.data.id!,
      institution: institutionHead.data.institution!,
      role: 'department_head',
      admin_Account: user!._id,
    };

    if (action === 'create') {
      payload.created_By = user!._id;
      MCreateDepartmentHead.mutate(payload);
    }

    if (action === 'edit') {
      if (data.id) {
        MUpdateDepartmentHead.mutate({ userId: data.id, body: payload });
      }
    }
  };

  return (
    <div className="">
      <div className="my-3">
        <Formik
          validationSchema={DepartmentHeadSchema}
          initialValues={data}
          onSubmit={submitDepartmentHeadForm}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form className="form" noValidate>
              <div className="">
                <hr />
                <h1 className="fs-5" id="addDepartmentHead">
                  <span className="text-capitalize">
                    {departmentHead.action}
                  </span>{' '}
                  Department Head
                </h1>
              </div>
              <div className="">
                <div className="row mb-4">
                  <div className="col">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="E.g. Sam Odanga"
                      aria-label="Name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label className="form-label">Email address</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="E.g name@example.com"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      disabled={action === 'edit'}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label className="form-label">Phone number</label>
                    <div className="input-group">
                      <span className="input-group-text">+256</span>

                      <input
                        type="text"
                        name="phoneNumber"
                        maxLength={9}
                        className="form-control"
                        placeholder="E.g 780000000"
                        aria-label="Phone"
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber}
                      />
                    </div>
                    <div className="text-danger">
                      <ErrorMessage name="phoneNumber" />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <label className="form-label">Department</label>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      size="large"
                      value={formik.values.department}
                      placeholder="Select department"
                      onChange={(value: string) => {
                        formik.setFieldValue('department', value);
                      }}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={department?.map((item) => {
                        return { value: item, label: item };
                      })}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="department" />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-center">
                <AppButton
                  btnType="secondary"
                  onClick={() => handleToggle(null)}
                >
                  cancel
                </AppButton>
                <span className="me-4"></span>
                <AppButton btnType="success" type="submit">
                  {action}
                </AppButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
