import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Formik, Form, FormikValues, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { IInstitutionHeadPayload, IInstitutionHeadForm } from '../core/models';
import { SCreateUser, SGetUsers, SUpdateUser } from '../../../apis/app.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import UserListItem from './UserListItem';
import AddDepartmentHeadForm from './AddDepartmentHeadForm';
import { useUsersPage } from '../../../store/UsersProvider';
import AppButton from '../../sharedcomponents/Buttons/AppButton/AppButton';
import { useAuth } from '../../../store/AuthProvider';

const userSchema = Yup.object({
  firstname: Yup.string().required().label('First Name'),
  lastname: Yup.string().required().label('Last Name'),
  email: Yup.string().email().required().label('Email'),
  organisationName: Yup.string().required().label('Institution'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{9}$/, 'Phone number must be exactly 9 digits')
    .required()
    .label('Phone'),
});

export default function CreateEditUser() {
  const { openCollapse, handleToggle, setDepartmentHead, institutionHead } =
    useUsersPage();
  const { user } = useAuth();

  const { action, data } = institutionHead;
  const { id } = data;
  const queryClient = useQueryClient();

  const {
    data: departmentHeads,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['departmentHeads'],
    queryFn: () => SGetUsers(id!, { role: 'department_head', parent: id }),
    enabled: !!id,
  });

  useEffect(() => {
    if (id) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const mutation = useMutation({
    mutationFn: SCreateUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      const { message } = data;

      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const mutationUpdate = useMutation({
    mutationFn: SUpdateUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      const { message } = data;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const submitForm = async (
    values: IInstitutionHeadForm,
    actions: FormikValues
  ) => {
    const payload: IInstitutionHeadPayload = {
      email: values.email,
      organisationName: values.organisationName,
      name: `${values.firstname} ${values.lastname}`,
      phoneNumber: `+256${values.phoneNumber}`,
      position: values.position,
      type: values.type,
      role: values.role,

      permissions: values.permissions,
    };

    if (action === 'create') {
      payload.admin_Account = user!._id;
      payload.created_By = user!._id;
      mutation.mutate(payload);
    } else if (action === 'edit' && id) {
      mutationUpdate.mutate({ userId: id, body: payload });
    }
  };
  return (
    <div className="modal fade" id="kt_modal_create_user" aria-hidden="true">
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: '900px' }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2>
              <span className="text-capitalize">{action}</span> Institution
            </h2>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            {/* begin body */}
            <div className="flex-row-fluid ">
              <Formik
                validationSchema={userSchema}
                initialValues={data}
                onSubmit={submitForm}
                enableReinitialize={true}
              >
                {(formik) => (
                  <Form className="form" noValidate>
                    <div className="w-100 mb-3 d-flex flex-column justify--center">
                      <div className="w-100">
                        <h4>Institution.</h4>
                        <div className="row mb-3">
                          <div className="col form-group">
                            <label
                              htmlFor="organisationName"
                              className="form-label fs-5"
                            >
                              Name
                            </label>

                            <Field
                              type="text"
                              className="form-control"
                              name="organisationName"
                            />
                            <div className="text-danger">
                              <ErrorMessage name="organisationName" />
                            </div>
                          </div>
                        </div>
                        {user?.role === 'sema_admin' ? (
                          <div className="form-group">
                            <label
                              htmlFor="permissions"
                              className="form-label fs-5"
                            >
                              Can Access
                            </label>
                            <div className="d-flex justify-content-between w-75 can-access">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    formik.values.permissions?.interviews
                                  }
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'permissions.interviews',
                                      e.target.checked
                                    );
                                  }}
                                />
                                <label className="form-check-label">
                                  Interviews
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={formik.values.permissions?.devices}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'permissions.devices',
                                      e.target.checked
                                    );
                                  }}
                                />
                                <label className="form-check-label">
                                  Devices
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={formik.values.permissions?.reports}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'permissions.reports',
                                      e.target.checked
                                    );
                                  }}
                                />
                                <label className="form-check-label">
                                  Reports
                                </label>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        <hr />
                        <h4>Institution head.</h4>

                        <div className="row mb-3">
                          <div className="col-md-6 form-group">
                            <label
                              htmlFor="firstname"
                              className="form-label fs-5"
                            >
                              First Name
                            </label>

                            <Field
                              type="text"
                              className="form-control"
                              name="firstname"
                              placeholder=""
                            />
                            <div className="text-danger">
                              <ErrorMessage name="firstname" />
                            </div>
                          </div>

                          <div className="col-md-6 form-group">
                            <label
                              htmlFor="lastname"
                              className="form-label fs-5"
                            >
                              Last Name
                            </label>

                            <Field
                              type="text"
                              className="form-control"
                              name="lastname"
                              placeholder=""
                            />
                            <div className="text-danger">
                              <ErrorMessage name="lastname" />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 form-group">
                            <label className="form-label fs-5">Email</label>

                            <Field
                              type="text"
                              className="form-control"
                              name="email"
                              placeholder=""
                              disabled={action === 'edit'}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="email" />
                            </div>
                          </div>
                          <div className="col-md-6 form-group">
                            <label
                              htmlFor="phoneNumber"
                              className="form-label fs-5"
                            >
                              Phone
                            </label>

                            <div className="input-group">
                              <span className="input-group-text">+256</span>

                              <input
                                type="text"
                                name="phoneNumber"
                                maxLength={9}
                                className="form-control"
                                placeholder="E.g 780000000"
                                aria-label="Phone"
                                onChange={formik.handleChange}
                                value={formik.values.phoneNumber}
                              />
                            </div>
                            <div className="text-danger">
                              <ErrorMessage name="phoneNumber" />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col form-group">
                            <label className="form-label fs-5">Position</label>

                            <Field
                              type="text"
                              className="form-control"
                              name="position"
                              placeholder="E.g Mayor"
                            />
                            <div className="text-danger">
                              <ErrorMessage name="position" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mx-auto">
                        <AppButton btnType="success" type="submit">
                          Save
                        </AppButton>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {action === 'edit' ? (
              <>
                <h3>New Role</h3>
                <ul className="list-group mb-4">
                  <li
                    className="list-group-item"
                    onClick={() => {
                      setDepartmentHead({
                        action: 'create',
                        data: {
                          name: '',
                          email: '',
                          phoneNumber: '',
                          department: '',
                        },
                      });
                      handleToggle(`create`);
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <span>Add New Role</span>
                      <span>
                        <i className="fa-solid fa-plus fs-4"></i>
                      </span>
                    </div>

                    <div
                      className={`collapse ${
                        openCollapse === `create` ? 'show' : ''
                      }`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <AddDepartmentHeadForm />
                    </div>
                  </li>
                </ul>
                <h3>Department Roles</h3>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div>
                      <div className="d-flex justify-content-between">
                        <div style={{ flexBasis: '40%' }}>
                          <h4 className="">Email</h4>
                        </div>
                        <div
                          className="align-self-center"
                          style={{ flexBasis: '25%' }}
                        >
                          <h4 className="">Department</h4>
                        </div>
                        <div
                          className="align-self-center"
                          style={{ flexBasis: '15%' }}
                        >
                          <h4>Status</h4>
                        </div>
                        <h4
                          className="align-self-center"
                          style={{ flexBasis: '20%' }}
                        >
                          Actions
                        </h4>
                      </div>
                    </div>
                  </li>
                  {!isFetching ? (
                    departmentHeads.map((user: any) => (
                      <li className="list-group-item" key={user.id}>
                        <UserListItem user={user} />
                      </li>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center my-2">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </ul>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
