import React, { createContext, useContext, useState, ReactNode } from 'react';
import { UsersContextType } from './core/models';
import { IDepartmentHead, IInstitutionHead } from '../pages/Users/core/models';

// Create Context
const UsersContext = createContext<UsersContextType | undefined>(undefined);

// Create a Provider Component
function UsersProvider({ children }: { children: ReactNode }) {
  const [institutionHead, setInstitutionHead] = useState<IInstitutionHead>({
    action: 'create',
    data: {
      email: '',
      organisationName: '',
      firstname: '',
      lastname: '',
      position: '',
      phoneNumber: '',
      type: '',
      role: 'institution_head',
      permissions: {
        interviews: true,
        devices: false,
        reports: true,
      },
    },
  });
  const [departmentHead, setDepartmentHead] = useState<IDepartmentHead>({
    action: 'create',
    data: {
      name: '',
      email: '',
      phoneNumber: '',
      department: '',
    },
  });
  const [newPassword, setNewPassword] = useState('');

  const [openCollapse, setOpenCollapse] = useState<string | null>(null);
  const handleToggle = (collapseId: string | null) => {
    if (collapseId) {
      setOpenCollapse(openCollapse === collapseId ? null : collapseId);
    } else {
      setOpenCollapse(null);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        departmentHead,
        setDepartmentHead,
        institutionHead,
        setInstitutionHead,
        openCollapse,
        handleToggle,
        newPassword,
        setNewPassword,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

// Custom hook for using the context
function useUsersPage() {
  const context = useContext(UsersContext);
  if (!context) {
    throw new Error('useUsersPage must be used within an UsersProvider');
  }
  return context;
}

export { UsersProvider, useUsersPage };
